import download_icon from "../icons/download.svg";
import audio_mute from "../icons/audio_mute.svg";
import audio_full from "../icons/audio_full.svg";
import React, { useState} from "react";
import Spinner from "./Spinner";
import { Helmet, HelmetProvider} from 'react-helmet-async'
import userinterface from "../icons/user_interface.svg";
import wideIcon from "../icons/audio-media-multimedia-13-svgrepo-com.svg";
import highquality from "../icons/video-quality-resolution-hd-icon.svg";
import secureIcon from "../icons/secure-svgrepo-com.svg";
import FAQ from "./FAQ";
import {useNavigate} from "react-router-dom";
const iconStyle = {
  height: "50px",
  width: "50px",
};
const Home = (props) => {
  const [link, setLink] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setdata] = useState(undefined)
  const [audioUrl, setAudioUrl] = useState(undefined);
  const [disabled, setDisabled] = useState(true);
  const [comVidThrsldVidSizeMB, setcombineVideoThresoldVideoSizeMB] = useState(0);
  const [comVidThrsldViddurSec, setcombineVideoThresoldVideoDurationSec] = useState(0);
  const navigate = useNavigate()  

  useState(()=>{
     window.scrollTo(0, 0)
     return undefined
  }, [])
  const onchange_handler = (e) => {
    setLink(e.target.value);
    setDisabled(false)
    // navigator.clipboard.readText().then(function (clipboardText) {
    // console.log("clipboardText =", clipboardText)
    // document.getElementById("pastebtn").disabled = false;
    // });
  };
  const paste_content = async () => {
    setLink(await navigator.clipboard.readText());
    // document.getElementById("pastebtn").disabled = true;
    setDisabled(false)
  };

  const handleSubmit = async () => {
    setLoading(true);
    setDisabled(true)
    setdata(undefined);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({ body: { url: link } }),
      redirect: "follow",
    };
    try {
      let response = await fetch(process.env.REACT_APP_API_URL, requestOptions);
      response = await response.json();
      setdata(response);
      if (response.statusCode === 200) {
        setcombineVideoThresoldVideoSizeMB(response.combine_video_thresold_video_mb)
        setcombineVideoThresoldVideoDurationSec(response.combine_video_thresold_video_sec)
        for (let index = 0; index < response.formats.length; index++) {
          if (response.formats[index].format_note === 'low') {
            setAudioUrl(response.formats[index].url);
            break
          }
        }
      }
    } catch (error) {
      console.log(error)      
      setdata({ statusCode: 500, errorMessage: "Please try again."});
    }
    setDisabled(false)
    setLoading(false);
  };

  return (
    <>
    <HelmetProvider>      
      <Helmet>
        <title> SaveTubeOnline: Online Video Downloader - SaveTubeOnline.com</title>
        <meta name="description"
          content="Download YouTube videos for free in MP4, MP3, and WEBM. Enjoy resolutions from 144p to 8K, including 4K, with our easy-to-use downloader tool."/>        
        <link rel="canonical" href="https://savetubeonline.com" />
      </Helmet>
    </HelmetProvider>

      <div className="container align-items-center text-center my-5">
        <div className="my-4">
          <div className="mb-3">
            <h1 className="h1">Welcome to YouTube Video Downloader</h1>
            <p>Fast and Free YouTube Video Downloader Online - Download Videos in HD</p>
            <input type="url" className="form-control" id="exampleFormControlInput1" placeholder="http:youtube.com/.." value={link} onChange={onchange_handler} required={true} />
          </div>
          <div className="col-auto">
            <button type="submit" id="pastebtn" className="btn btn-primary mb-3 mx-1" onClick={paste_content}>
              Paste Link
            </button>
            <button type="submit" id="sbmt1" disabled={disabled}  onClick={handleSubmit} className="btn btn-primary mb-3 mx-1">
              Submit
            </button>
          </div>
          {loading && <Spinner />}
          {Boolean(data) && data.statusCode === 200 && (
            <div className="row justify-content-center">
            <div className="col-lg-6 col-sm-12">
              <div className="card align-items-center">
                <div style={{ position: "relative", textAlign: "center", color: "white" }}>
                  <img src={data.thumbnail} className="img-thumbnail rounded" height="300px" width="300px" alt="blank" />
                  <div style={{ position: "absolute", bottom: "8px", right: "16px" }} >
                    {data.duration} sec
                  </div>
                </div>
                <div className="card-body">
                  <h5 className="card-title">{data.title}</h5>
                    {/* <p className="card-text">{data.description.slice(0)}</p>                                         */}
              </div>
              <div className="table-responsive">
                <table className="table table-sm  align-middle  mb-0">
                  <thead>
                    <tr>
                      <th scope="col">resolution</th>
                      <th scope="col">format</th>
                      <th scope="col">filesize</th>
                      <th scope="col">ext</th>
                      <th scope="col">Download</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.formats.map((i) => {
                      return (
                        <tr className="table" key={i.url}>
                          <td>{i.resolution}</td>
                          <td>{i.format_note}{" "}
                            {i.audio_channels === 2 || (data.duration < comVidThrsldViddurSec && (i.filesize / 1000000) < comVidThrsldVidSizeMB && audioUrl)? 
                            <img src={audio_full}  style={{color: "#0f5ee6", padding:"0px" ,height:"15px", width:"15px" }}  alt="audio" /> :
                             <img src={audio_mute}  style={{color: "#0f5ee6", padding:"0px" ,height:"15px", width:"15px" }}  alt="no_audio" />}
                          {/*  {i.audio_channels === 2 ? <i class="fa-solid fa-volume-high"  style={{color: "#0f5ee6"}}></i> : <i class="fa-solid fa-volume-xmark" style={{color: "#0f5ee6"}}></i>} */}
                          </td>
                          <td>{`${i.filesize ? parseFloat(i.filesize / 1000000).toFixed(2) + " MB" : "-"}`}</td>
                          <td>{i.ext}</td>
                          <td>
                          {i.audio_channels === 2 || data.duration > comVidThrsldViddurSec || (i.filesize / 1000000) > comVidThrsldVidSizeMB || (!audioUrl) ? 
                            <a target="_blank" rel="noreferrer" href={`${i.url}?title=${data.title}`} download={data.title}>
                              <img src={download_icon} style={{color: "#0f5ee6", padding:"0px" ,height:"15px", width:"15px" }}  alt="download" />
                              </a>                              
                              :
                               <span onClick={()=>{navigate("/download", {state:{audio_url: audioUrl,video_url:i.url,title:data.title,resolution:i.resolution,format_note:i.format_note}})}} >
                                {/* audi+video */}
                          <img src={download_icon} style={{color: "#0f5ee6", padding:"0px" ,height:"15px", width:"15px" }}  alt="download" />                          
                                </span>                          
                    }
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                  </div>
                </div>
              </div>
            </div>
          )}
          {Boolean(data) && data.statusCode !== 200 && (
            <div className="container">{data.errorMessage}</div>
          )}
        </div>
        {/* adsterra  native banner*/}        
        <div className="mobileShow" id="container-4d3d59ad791e4c31db33722b3088dff2"></div>
        <div  className="mobileHide" id="container-4d3d59ad791e4c31db33722b3088dff2"></div>
        <p className="text-muted" >SaveTubeOnline video downloader is a leading online platform dedicated to providing users with easy access to their favorite YouTube videos. Our mission is to simplify the process of downloading and saving videos from YouTube, whether you need a YouTube video audio downloader, a YouTube video MP3 downloader, or a YouTube to video downloader, allowing users to enjoy their favorite content anytime, anywhere.</p>
      </div>
      <main>
        <section style={{ overflowWrap: "anywhere", backgroundColor: "#08003b", color: "white" }}>
          <div className="container pt-5 pb-5 align-items-center color-white bg-08003a">
            <div className="container">
              <h2>How to download videos from YouTube</h2>
              {/* <p>Looking to preserve the pristine HD quality of your MP4 videos? Discover proven techniques to make it happen:</p> */}
              <ol>
                <li>Copy the URL of the YouTube video you want to download.</li>
                <li>Paste the URL or click paste button.</li>
                <li>Click the "Submit" button.</li>
                <li>SaveTubeOnline will generate various Audio/Video format options for you to download.</li>
                <li>Now choose your video/audio from list and press download button</li>
              </ol>
            </div>
          </div>
        </section>

        {/* features section */}
        <section>
          <div className="container my-5">
            <div className="text-center">
              <h2>SaveTubeOnline Video Downloader Features</h2>
              <p className="lead">List of top features</p>
            </div>
            <div className="row g-4 justify-content-center">
              <div className="col-md-4">
                <div className="card h-100 text-center p-3">
                  <div className="card-body">
                    <img src={userinterface} alt="User Interface" className="mb-3"
                      style={{
                        ...iconStyle, backgroundColor: props.mode === "Dark Mode" ? "white" : "cyan",
                      }}/>
                    <h3 className="card-title">User Friendly Interface</h3>
                    <p className="card-text">
                      Our platform is designed with simplicity in mind, making
                      it easy for anyone to download YouTube videos quickly and
                      efficiently.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card h-100 text-center p-3">
                  <div className="card-body">
                    <img src={wideIcon} alt="Wide Formats" className="mb-3" 
                    style={{
                        ...iconStyle,
                        backgroundColor:
                          props.mode === "Dark Mode" ? "white" : "cyan",
                      }}
                    />
                    <h3 className="card-title">Wide Range of Formats</h3>
                    <p className="card-text">
                      Choose from a variety of video and audio formats,
                      including MP4, MP3, WEBM, and more.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card h-100 text-center p-3">
                  <div className="card-body">
                    <img src={highquality} alt="High Quality" className="mb-3" style={{
                        ...iconStyle,
                        backgroundColor:
                          props.mode === "Dark Mode" ? "white" : "cyan",
                      }}
                    />
                    <h3 className="card-title">High-Quality Downloads</h3>
                    <p className="card-text">
                      Enjoy high-quality downloads with resolutions up to
                      1080p,4k, ensuring that you get the best viewing
                      experience.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 order-md-2 order-4">
                  <div className="card h-100 text-center p-3">
                    <div className="card-body">
                      <img src={secureIcon} alt="Fast & Secure" className="mb-3" style={{
                          ...iconStyle,
                          backgroundColor:
                            props.mode === "Dark Mode" ? "white" : "cyan",
                        }}
                      />
                      <h3 className="card-title">Fast and Secure</h3>
                      <p className="card-text">
                        Our platform uses advanced technology to ensure fast and
                        secure downloads, protecting your privacy and data at
                        all times.
                      </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        
        {/* FAQ */}
        <FAQ/>
      </main>
    </>
  );
};

export default Home;
