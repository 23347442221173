import audio_full_transparent from "../icons/audio_full_transparent.svg";
import audio_mute_transparent from "../icons/audio_mute_transparent.svg";
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import FAQ from './FAQ'
import Spinner from './Spinner'


const Download = props => {
    const location = useLocation()
    // const [data, setData] = useState()
    const [message, setMessage] = useState(undefined)
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
        if (!location.state)
            navigate("/")
        // window.history.replaceState({}, '')
    }, []);

    const download_video = async (title, audio_url, video_url) => {
        setLoading(true)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: "POST", headers: myHeaders, redirect: "follow",
            body: JSON.stringify({ body: { title: title, audio_url: audio_url, video_url: video_url } }),
        };
        try {
            setMessage(undefined)
            let response = await fetch(process.env.REACT_APP_VIDEO_COMBINE_URL, requestOptions);
            response = await response.json();
            // setData(response)
            // console.log(response)
            if (response.statusCode === 200) {
                setMessage(<p>if download not start automatically then <a id="download_vid" target="_blank" rel="noreferrer" href={response.url}> click here</a></p>)
                setTimeout(() => { document.getElementById("download_vid").click() }, 1500)
            }
            else
                setMessage(<p>please try again or later</p>)
        }
        catch (error) {
            setMessage(<p>go back and try again.</p>)
            // setData({ statusCode: 500, errorMessage: "go back and try again" });
        }
        setLoading(false);
        setTimeout(() => { document.getElementById("ad1").click() }, 1500)
    }

    return (
        location.state && <>
            <div className="container my-5">
                <div className="row g-4 justify-content-center  text-center">
                    <div className="col-auto">
                        <h1>Thank you for using SaveTubeOnline...</h1>
                        <h3>{location.state.title + " " + location.state.resolution + " " + location.state.format_note}</h3>
                        <button type="submit" onClick={() => { navigate(-1) }} id="bk1" className="btn btn-primary mb-3 mx-1">
                            &larr;Back
                        </button>
                        <a className="btn btn-primary mb-3 mx-1" target="_blank" rel="noreferrer" href={`${location.state.video_url}?title=${location.state.title}`} download={location.state.title}>
                            {"Download "}
                            <img src={audio_mute_transparent} style={{ color: "#0f5ee6", padding: "0px", height: "20px", width: "20px" }} alt="audio" />
                        </a>
                        <button className="btn btn-primary mb-3 mx-1" type="submit" onClick={() => { download_video(location.state.title, location.state.audio_url, location.state.video_url) }} id="dw1" >
                            {"Download "}<img src={audio_full_transparent} style={{ color: "#0f5ee6", padding: "0px", height: "20px", width: "20px" }} alt="audio" />
                        </button>
                        {message}
                        {loading && <Spinner />}
                        {/* {data && data.statusCode === 200 && <p>if download not start automatically then <a target="_blank" rel="noreferrer" href={data.url}>  click here</a></p>}
                        {data && data.statusCode !== 200 && <p>please try again or later</p>} */}
                        <a id="ad1" style={{ visibility: "hidden" }} target="_blank" rel="noreferrer" href="https://plumpobstructionmortal.com/kim2jbit8?key=211a41b512559a1ebea1c99321e22d95">a</a>
                        {/* adsterra  native banner*/}
                        <div className="mobileShow" id="container-4d3d59ad791e4c31db33722b3088dff2"></div>
                        <div className="mobileHide" id="container-4d3d59ad791e4c31db33722b3088dff2"></div>
                    </div>
                </div>
            </div>
            <FAQ />
        </>
    )
}

export default Download;
