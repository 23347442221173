import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const Navbar = props => {
  const [modeIcon, setModeIcon] = useState('moon')

  const changeMode = () => {
    if (document.documentElement.getAttribute('data-bs-theme') === 'dark') {
      document.documentElement.setAttribute('data-bs-theme', 'light')
      props.SetMode("Dark Mode")
      setModeIcon('moon')
    } else {
      document.documentElement.setAttribute('data-bs-theme', 'dark')
      props.SetMode("Light Mode")
      setModeIcon('sun')
    }
  }

  return (
    <>
      <div>
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
          <div className="container-fluid">
            <Link className="navbar-brand" to="/">SaveTubeOnline</Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item"> <Link className="nav-link active" aria-current="page" to="">Home</Link></li>
                <li className="nav-item"> <Link className="nav-link active" aria-current="page" to="privacy-policy">Privacy Policy</Link></li>
                {/* <li className="nav-item"> <Link className="nav-link active" aria-current="page" to="faq">FAQ</Link></li> */}
                <li className="nav-item"> <Link className="nav-link active" aria-current="page" to="contact">Contact Us</Link></li>
                <li className="nav-item"> <Link className="nav-link active" aria-current="page" to="about">About Us</Link></li>
              </ul>
              <button type="submit" id="uimodeid1" className="btn btn-primary rounded-pill" onClick={changeMode}>
              {modeIcon === 'moon' ? (<span role="img" aria-label="moon">&#127769;</span> ):(<span role="img" aria-label="sun">&#9728;</span> )}
              {/* {modeIcon === 'moon' ? (<span role="img" aria-label="moon">🌜</span> ):(<span role="img" aria-label="sun">🌞</span> )} */}
              </button>
            </div>
          </div>
        </nav>
      </div>
    </>
  )
}

export default Navbar
