import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'


const ContactUs = () => {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>ContactUs - SaveTubeOnline</title>
          <meta name="description"
            content="If you have any questions, complaints, or claims with respect to the our Services, please send us a message to:contactsavetubeonline@gmail.com" />
          <link rel="canonical" href="https://savetubeonline.com/contact" />
        </Helmet>
      </HelmetProvider>


      <div className='container my-5' style={{ minHeight: "75vh"}}>
        <h1 className="h1">Contact Us</h1>
        <p></p> If you have any questions, complaints, or claims with respect to the our Services, please send us a message to:
        <a href="mailto:contact@savetubeonline.com" > contact@savetubeonline.com</a>
      </div>
    </>
  )
}

export default ContactUs;
