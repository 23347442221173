import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (

        <footer className="bg-dark text-white text-center py-1">
        <div className="container">
            <div className="row">
                <div className="col">
                    <p>&copy; 2024 savetubeonline.com</p>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <ul className="list-inline">
                        <li className="list-inline-item"><Link to="">Home</Link></li>
                        {/* <li className="list-inline-item"><Link to="about">About Us</Link></li> */}
                        <li className="list-inline-item"><Link to="privacy-policy">Privacy Policy</Link></li>
                        <li className="list-inline-item"><Link to="disclaimer">Disclaimer</Link></li>
                        {/* <li className="list-inline-item"><Link to="faq">FAQ</Link></li> */}
                        <li className="list-inline-item"><Link to="terms-and-conditions">T&C</Link></li>
                        <li className="list-inline-item"><Link to="about">About Us</Link></li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>     
    )
}

export default Footer
