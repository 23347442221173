import React, { useEffect, useState, 
  // Suspense, lazy
 } from 'react';
import Navbar from './componants/Navbar';

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
// import LoadingBar from 'react-top-loading-bar'
import Home from './componants/Home';
import Download from './componants/Download'
import ContactUs from './componants/ContactUs'
import Footer from './componants/Footer'
import Disclaimer from './componants/Disclaimer'
import PrivacyPolicy from './componants/PrivacyPolicy'
import FAQ from './componants/FAQ'
import TermsCondition from './componants/TermsCondition'
import AboutUs from './componants/AboutUs'

const App = props => {
  // const progress = 100
  // apiKey: process.env.REACT_APP_API_KEY
  const [mode, SetMode] = useState(document.documentElement.getAttribute('data-bs-theme') === 'dark' ? "Light Mode" : "Dark Mode")

  useEffect(() => {
    // Disable right-click context menu
    // document.addEventListener('contextmenu', disableContextMenu);
    // Disable specific keydown events for inspect tools
    document.addEventListener('keydown', disableInspectShortcuts);
    return () => {
      // Cleanup event listeners on component unmount
      document.removeEventListener('contextmenu', disableContextMenu);
      document.removeEventListener('keydown', disableInspectShortcuts);
    }
  }, [])


  const disableContextMenu = (event) => {
    event.preventDefault();
  }

  const disableInspectShortcuts = (event) => {
    if (
      event.keyCode === 123 || // F12
      (event.ctrlKey && event.shiftKey && event.keyCode === 73) || // Ctrl+Shift+I
      (event.ctrlKey && event.shiftKey && event.keyCode === 74) || // Ctrl+Shift+J
      (event.ctrlKey && event.keyCode === 85) || // Ctrl+U
      (event.ctrlKey && event.shiftKey && event.keyCode === 67) // Ctrl+Shift+C
    ) {
      event.preventDefault();
    }
  }

  return (
    <>
      {/* <LoadingBar color='#f11946' height={3} progress={100} /> */}
      <Router>
        <Navbar mode={mode} SetMode={SetMode} />
        {/* <Suspense fallback={<LoadingBar />}> */}
          <Routes>
            <Route path="" element={<Home mode={mode} />} />
            <Route path="contact" element={<ContactUs />} />
            <Route path="disclaimer" element={<Disclaimer />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="faq" element={<FAQ />} />
            <Route path="terms-and-conditions" element={<TermsCondition />} />
            <Route path="about" element={<AboutUs />} />
            <Route path="download" element={<Download />} />
            <Route path="*" element={<Home />} />
          </Routes>
        {/* </Suspense> */}
        <Footer />
      </Router>
    </>
  )
}
export default App;