import React from 'react'
// import { Helmet } from 'react-helmet'
const FAQ = () => {
    const data = [
        {
            question: "How can I download videos from YouTube?",
            answer: `To download videos from YouTube, simply copy the URL of the video you want to download, open savetubeonline.com, paste it into the download form on our website's main page, and click the "Download" button.`,
            id: "panelsStayOpen-collapseOne"
        },
        {
            question: "What formats and sizes are available for downloading?",
            answer: `Our YouTube downloader supports a variety of formats and sizes, including MP4, WebM, and MP3,MP4a, and resolutions ranging from 144p, to 2180P. You can choose the format and size that best suits your needs before downloading the video.`,
            id: "panelsStayOpen-collapseTwo"
        },
        {
            question: "Is your YouTube downloader free to use?",
            answer: `Yes, our YouTube downloader is completely free to use. You can download videos from YouTube without any cost or subscription fees.`,
            id: "panelsStayOpen-collapseThree"
        },
        {
            question: "Can I download videos from YouTube using your website on mobile devices?",
            answer: `Yes, our website is mobile-friendly, and you can use our YouTube downloader on any device with a web browser, including smartphones and tablets.`,
            id: "panelsStayOpen-collapseFour"
        },
        {
            question: "Is it legal to download videos from YouTube?",
            answer: `It is important to respect the terms of service of YouTube and the rights of content creators. While it is generally allowed to download videos from YouTube for personal use, it may be prohibited to download certain videos that are protected by copyright or other intellectual property rights.`,
            id: "panelsStayOpen-collapseFive"
        },
        {
            question: "how to download videos from youtube to computer?",
            answer: `To download videos from YouTube, simply copy the URL of the video you want to download, open savetubeonline.com, paste it into the download form on our website's main page, and click the "Download" button.`,
            id: "panelsStayOpen-collapseSix"
        },
        {
            question: "How can I download YouTube Shorts on mobile?",
            answer: `To download shorts from YouTube in mobile, simply copy the URL of the shorts you want to download, open savetubeonline.com, paste it into the download form on our website's main page, and click the "Download" button.`,
            id: "panelsStayOpen-collapseSeven"
        },
        {
            question: "How can I download YouTube Shorts on computer?",
            answer: `To download shorts from YouTube in computer, simply copy the URL of the shorts you want to download, open savetubeonline.com, paste it into the download form on our website's main page, and click the "Download" button.`,
            id: "panelsStayOpen-collapseEight"
        },
        {
            question: "How can I download YouTube Shorts?",
            answer: `To download shorts from YouTube App, simply copy the URL of the shorts you want to download, open savetubeonline.com, paste it into the download form on our website's main page, and click the "Download" button.`,
            id: "panelsStayOpen-collapseNine"
        },
    ]
    return (<>
        {/* <Helmet>
            <title>FAQ - SaveTubeOnline</title>
            <meta name="description"
                content="faq" />
            <link rel="canonical" href="https://savetubeonline.com/faq" />
        </Helmet> */}

        <div className='container  my-5'>
            <h2 className='h2'>Frequently Asked Questions (FAQ)</h2>
            <div className="accordion" id="accordionPanelsStayOpenExample">
                {data.map((i) =>
                    <div className="accordion-item" key={i.id}>
                        <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#" + i.id} aria-expanded="true" aria-controls={i.id}>
                                <b>{i.question}</b>
                            </button>
                        </h2>
                        <div id={i.id} className="accordion-collapse collapse hide">
                            <div className="accordion-body">
                                <i>{i.answer}</i>
                            </div>
                        </div>
                    </div>)}
            </div>
        </div>
    </>
    )
}

export default FAQ
