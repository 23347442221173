import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async'

const AboutUs = () => {
  return (
    <>
      <HelmetProvider>

        <Helmet>
          <title>About Us - SaveTubeOnline</title>
          <link rel="canonical" href="https://savetubeonline.com/about" />
          <meta name="description"
            content="savetbubeonline is leading online video downloader.provide High quality videos and audio in various format." />        
        </Helmet>
      </HelmetProvider>


      <div className='container my-5' style={{ minHeight: '75vh'}}>
        <h1>About Us</h1>
        <p>
          <b id="W_Name2">SaveTubeOnline</b>  is a Professional {' '}
          <span id="W_Type1">video downloader </span> Platform. We are committed to providing you the best of{' '}
          <span id="W_Type2">video downloader </span>, with its user-friendly interface, Wide Range of Formats, High-Quality Downloads and{' '}
          <span id="W_Spec">fast performance</span>. We strive to turn our passion for{' '}
          <span id="W_Type3">Entertainment </span> into a thriving website. We hope you enjoy our{' '}
          <span id="W_Type4">site </span> as much as we enjoy giving them to you.
        </p>
        <p>
          We are keep on trying to bring more conents and features on this Website for all of you. Your love and
          support matter a lot.
        </p>
        <p style={{ fontWeight: 'bold', textAlign: 'center' }}>
          Thank you For Visiting Our Site
          <br />
          <br />
        </p>
      </div>
    </>
  );
};

export default AboutUs;
